import { useState } from "react";

const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = event => {
    setAnchorEl(event.currentTarget);
  };
  const close = () => {
    setAnchorEl(null);
  };

  return [anchorEl, { open, close }];
};

export default useMenu;
