import { Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const Address = ({ address }) => {
  return (
    <div>
      {address?.street && <Typography>{address?.street}</Typography>}
      {address?.suite && <Typography>{address?.suite}</Typography>}
      <Typography>
        {address?.city}, {address?.state} {address?.zip}
      </Typography>
    </div>
  );
};

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string.isRequired,
    suite: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
};

export default Address;
