/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./header";
import Footer from "./footer";
import { makeStyles } from "@material-ui/core";
import theme from "../theme";

const useStyles = makeStyles(theme => ({
  site: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  siteContent: {
    flexGrow: 1,
    marginBottom: ({ noGutterBottom }) =>
      noGutterBottom ? 0 : theme.spacing(2),
    marginTop: ({ noGutterTop }) => (noGutterTop ? 0 : theme.spacing(2)),
  },
}));

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const { children } = props;
  const classes = useStyles(props);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className={classes.site}>
        <Header siteTitle={data.site.siteMetadata?.title} />
        <main className={classes.siteContent}>{children}</main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noGutterBottom: PropTypes.bool,
  noMarginTop: PropTypes.bool,
};

Layout.defaultProps = {
  noGutterBottom: false,
  noGutterTop: false,
};

export default Layout;
