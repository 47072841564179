import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "../icons/menu-icon";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LangSelector from "./lang-selector-simple";
import ForwardedLink from "../shared/components/forwarded-link";
import useMenu from "../lib/hooks/use-menu";
import { graphql, useStaticQuery } from "gatsby";

const useStyles = makeStyles(theme => ({
  wrapper: {
    zIndex: 1000,
  },
  root: {
    flexGrow: 1,
    "& > *": {
      backgroundColor: "white",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    paddingLeft: "0.5em",
    flexGrow: 1,
  },
  noLink: { textDecoration: `none` },
}));

const ServicesMenu = ({ servicesAnchor, closeServicesMenu }) => {
  // NOTE this is hacky and I have to do it only because static queries don't supports arguments
  const data = useStaticQuery(graphql`
    query NavServices {
      services: allMdx(
        sort: { fields: frontmatter___date, order: ASC }
        filter: { fileAbsolutePath: { regex: "/services/" } }
      ) {
        services: nodes {
          frontmatter {
            title
            language
          }
          slug
        }
      }
    }
  `);
  // HACK: dynamically find file with correct language, this is happening at react runtime
  const { language } = useI18next();
  const services = data?.services?.services?.filter(
    service => service.frontmatter.language === language
  );

  return (
    <Menu
      id="nav-services-menu"
      anchorEl={servicesAnchor}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(servicesAnchor)}
      onClose={closeServicesMenu}
    >
      {services.map(service => (
        <MenuItem
          key={service.frontmatter.title}
          onClick={closeServicesMenu}
          component={ForwardedLink}
          to={`/${service.slug.split(".")[0]}`}
        >
          {service.frontmatter.title}
        </MenuItem>
      ))}
    </Menu>
  );
};

const LargeNav = () => {
  const { t } = useTranslation();
  const [servicesAnchor, { open: openServicesMenu, close: closeServicesMenu }] =
    useMenu();

  return (
    <>
      <>
        <Button
          color="primary"
          aria-controls="nav-services-menu"
          aria-haspopup="true"
          onClick={openServicesMenu}
        >
          {t("nav.services")}
        </Button>
        <ServicesMenu
          servicesAnchor={servicesAnchor}
          closeServicesMenu={closeServicesMenu}
        />
      </>
      {/* <Button color="primary" component={ForwardedLink} to="/client-resources">
        {t("nav.client-resources")}
      </Button> */}
      <Button color="primary" component={ForwardedLink} to="/blog">
        {t("nav.blog")}
      </Button>
      <Button color="primary" component={ForwardedLink} to="/about">
        {t("nav.about")}
      </Button>

      <Button
        color="secondary"
        variant="outlined"
        component={ForwardedLink}
        to="/contact-us"
      >
        {t("nav.contact-us")}
      </Button>
      <LangSelector />
    </>
  );
};

const DrawerNav = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const [servicesAnchor, { open: openServicesMenu, close: closeServicesMenu }] =
    useMenu();

  return (
    <>
      <IconButton edge="start" aria-label="menu" onClick={openDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <div style={{ width: "250px" }} role="presentation">
          <List>
            <ListItem button>
              <ListItemText
                primary={t("nav.services")}
                aria-controls="nav-services-menu"
                aria-haspopup="true"
                onClick={openServicesMenu}
              />
              <ServicesMenu
                servicesAnchor={servicesAnchor}
                closeServicesMenu={closeServicesMenu}
              />
            </ListItem>
            {/* <ListItem button component={ForwardedLink} to="/client-resources">
              <ListItemText primary={t("nav.client-resources")} />
            </ListItem> */}
            <ListItem button component={ForwardedLink} to="/blog">
              <ListItemText primary={t("nav.blog")} />
            </ListItem>
            <ListItem button component={ForwardedLink} to="/about">
              <ListItemText primary={t("nav.about")} />
            </ListItem>
            <ListItem button component={ForwardedLink} to="/contact-us">
              <ListItemText primary={t("nav.contact-us")} />
            </ListItem>
          </List>
          <LangSelector />
        </div>
      </Drawer>
    </>
  );
};

const Header = ({ siteTitle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Link to="/" className={classes.title}>
              <StaticImage
                src="../images/rpf-logo.jpg"
                layout="constrained"
                width={125}
                alt={siteTitle}
                placeholder="None"
              />
            </Link>

            {isLarge ? <LargeNav /> : <DrawerNav />}
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
