import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import ForwardedLink from "../shared/components/forwarded-link";

const useStyles = makeStyles(() => ({
  imageIcon: {
    width: "100%",
  },
  iconRoot: {
    display: "flex",
    textAlign: "center",
  },
}));

const getFlagUrl = lng => {
  if (lng === "en") {
    lng = "us";
  }
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${lng.toUpperCase()}.svg`;
};

const LangSelector = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language, originalPath } = useI18next();

  const nextLang = language === "en" ? "ru" : "en";

  return (
    <div>
      <Tooltip title={t("nav.switch-to-lng", { lang: nextLang.toUpperCase() })}>
        <IconButton
          aria-label={t("nav.choose-lng")}
          component={ForwardedLink}
          to={originalPath}
          language={nextLang}
        >
          <Icon className={classes.iconRoot}>
            <img
              className={classes.imageIcon}
              src={getFlagUrl(nextLang)}
              alt={language}
            />
          </Icon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default LangSelector;
