import React from "react";
import { IconButton, Link, makeStyles, Typography } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import Address from "../components/address";
import Facebook from "../icons/facebook";
import LinkedIn from "../icons/linkedin";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "lightgrey",
    padding: theme.spacing(4),
    "& *": {
      fontSize: "1em",
    },
  },
  details: {
    display: "flex",
    flexWrap: "wrap",
    "& > :first-child": {
      marginRight: theme.spacing(4),
    },
  },
  copyright: {},
  address: {
    marginBottom: "1em",
  },
  contactInfo: {
    marginBottom: "1em",
  },
  social: {
    // alignSelf: "center",
  },
  icon: {
    fontSize: "2em",
  },
}));

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    site: {
      siteMetadata: { title, email, phone, address, linkedin, facebook },
    },
  } = useStaticQuery(graphql`
    query FooterMetadataQuery {
      site {
        siteMetadata {
          title
          author
          email
          phone
          address {
            street
            suite
            city
            state
            zip
          }
          linkedin
          facebook
        }
      }
    }
  `);

  return (
    <footer className={classes.footer}>
      <div className={classes.details}>
        <div className={classes.address}>
          <Typography variant="h6">{t("footer.address")}</Typography>
          <Address address={address} />
        </div>
        <div className={classes.contactInfo}>
          <Typography variant="h6">{t("footer.contact")}</Typography>
          <Typography>{phone}</Typography>
          <Link href={`mailto:${email}`} target="_top">
            {email}
          </Link>

          <div className={classes.social}>
            <IconButton
              color="primary"
              aria-label="facebook"
              component="a"
              href={facebook}
              size="medium"
            >
              <Facebook className={classes.icon} />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="instagram"
              component="a"
              href={linkedin}
            >
              <LinkedIn className={classes.icon} />
            </IconButton>
          </div>
        </div>
      </div>

      <div className={classes.copyright}>
        <Typography variant="subtitle2">
          © {new Date().getFullYear()} {title}
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
