import React from "react";
import { SvgIcon } from "@material-ui/core";

const MenuIcon = props => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
    />
  </SvgIcon>
);

export default MenuIcon;
